/* eslint-disable max-len */
const quizQuestionsLab3 = [
  {
    question: "How many people around the world have visual impairments?",
    answers: [
      {
        val: 0,
        type: "0",
        content: "12 million",
      },
      {
        val: 1,
        type: "1",
        content: "285 million",
      },
      {
        val: 0,
        type: "2",
        content: "39 million",
      },
      {
        val: 0,
        type: "3",
        content: "500 million",
      },
    ],
    multiChoice: false,
  },
  {
    question: "What is the most popular screen reader currently available?",
    answers: [
      {
        val: 0,
        type: "0",
        content: "COBRA",
      },
      {
        val: 0,
        type: "1",
        content: "BRLTTY",
      },
      {
        val: 1,
        type: "2",
        content: "JAWS",
      },
      {
        val: 0,
        type: "3",
        content: "iMax for Mac",
      },
    ],
    multiChoice: false,
  },
  {
    question:
      "Which of the following is one way to make a screen reader interface more user-friendly?",
    answers: [
      {
        val: 1,
        type: "0",
        content: "Use many headings and subheadings",
      },
      {
        val: 0,
        type: "1",
        content: "Increase the number of paragraphs",
      },
      {
        val: 0,
        type: "2",
        content: "Decrease text size",
      },
      {
        val: 0,
        type: "3",
        content: "Increase the number of buttons and links",
      },
    ],
    multiChoice: false,
  },
  {
    question:
      "About what percentage of all blind people are aged 50 years or older?",
    answers: [
      {
        val: 0,
        type: "0",
        content: "55%",
      },
      {
        val: 0,
        type: "1",
        content: "70%",
      },
      {
        val: 1,
        type: "2",
        content: "82%",
      },
      {
        val: 0,
        type: "3",
        content: "92%",
      },
    ],
    multiChoice: false,
  },
  {
    question:
      "Which of the following should you do to ensure that an image is made accessible to a screen reader?",
    answers: [
      {
        val: 0,
        type: "0",
        content: "Make the image smaller",
      },
      {
        val: 1,
        type: "1",
        content:
          "Use an alt attribute to communicate the function of the image",
      },
      {
        val: 1,
        type: "2",
        content: "Provide a brief description of the image",
      },
      {
        val: 0,
        type: "3",
        content: "Use it to add ambiance or visual interest to the page",
      },
    ],
    multiChoice: true,
  },
];

export default quizQuestionsLab3;
